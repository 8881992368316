import React, {Component} from "react";
import TopNav from "../../common/nav/TopNav";
import Footer from "../../common/Footer";
import PageBlock from "../../common/PageBlock";

class Contact extends Component {
    render() {
        return (
            <div>
                <TopNav tab="contact"/>

                <PageBlock>
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <h1 className="page-header">Contact us</h1>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <p>
                                    If you have any questions, queries or suggestions about Gatehill Software, please feel free to contact us.
                                </p>
                                <p>
                                    You can reach our friendly team at <a href="mailto:hello@gatehill.io">hello@gatehill.io</a> or talk to us
                                    on <a href="https://twitter.com/gatehill_io">Twitter</a>.
                                </p>
                            </div>
                        </div>

                    </div>
                </PageBlock>

                <Footer/>
            </div>
        );
    }
}

export default Contact;
