import React, {Component} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import "./TopNav.css";

class TopNav extends Component {
    render() {
        return (
            <nav className="navbar navbar-inverse navbar-fixed-top top-nav">
                <div className="container-fluid">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                        </button>
                        <Link className="navbar-brand" to="/">
                            <img
                                alt="Gatehill Software logo"
                                className="brand-icon"
                                src="/assets/img/gatehillsoftware_logo01_notext_128_white.png"/>
                        </Link>
                        <Link className="navbar-brand" to="/">Gatehill Software</Link>
                    </div>

                    <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                        <ul className="nav navbar-nav">
                            <li className={"visible-xs " + (this.props.tab === 'home' ? "active" : "")}><Link to="/">Home</Link></li>
                            <li className={this.props.tab === 'products' ? "active" : ""}><Link to="/products">Products</Link></li>
                            <li className={this.props.tab === 'about' ? "active" : ""}><Link to="/about">About</Link></li>
                            <li className={this.props.tab === 'contact' ? "active" : ""}><Link to="/about/contact">Contact</Link></li>
                        </ul>
                        <ul className="nav navbar-nav navbar-right">
                            <li className="nav-item dropdown">
                                <a href="#legal"
                                   className={`nav-link dropdown-toggle ${this.props.tab === 'legal' ? "active" : ""}`}
                                   data-toggle="dropdown"
                                   role="button"
                                   aria-haspopup="true"
                                   aria-expanded="false">Legal <span className="icon icon-chevron-down"/></a>

                                <ul className="dropdown-menu" role="menu">
                                    <li><Link className="dropdown-item" to="/legal/terms">Terms</Link></li>
                                    <li><Link className="dropdown-item" to="/legal/privacy">Privacy</Link></li>
                                    <li><Link className="dropdown-item" to="/legal/license">License</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

TopNav.propTypes = {
    tab: PropTypes.string
};

export default TopNav;
