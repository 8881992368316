import React, {Component} from "react";
import TopNav from "../../common/nav/TopNav";
import Footer from "../../common/Footer";
import PageBlock from "../../common/PageBlock";
import {Link} from "react-router-dom";

class About extends Component {
    render() {
        return (
            <div>
                <TopNav tab="about"/>

                <PageBlock>
                    <div className="container">

                        <div className="row">
                            <div className="col-lg-12">
                                <h1 className="page-header">About us</h1>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-sm-12">
                                <p>
                                    We believe your minutes matter.
                                </p>
                                <p>
                                    At Gatehill Software, we've been building software
                                    for over two decades to help people make the most
                                    of their time.
                                </p>
                                <h3>What we do</h3>
                                <p>
                                    Gatehill Software creates both commercial and free apps, and maintains and contributes to a number of open source projects.
                                </p>
                                <h4>Open source</h4>
                                <p>
                                    View our projects here:
                                </p>
                                <p className="m-b-md">
                                    <a className="btn btn-info m-t-sm m-r-sm" href="https://github.com/gatehill">Gatehill on GitHub</a>
                                    <a className="btn btn-info m-t-sm m-r-sm" href="https://github.com/outofcoffee?tab=repositories">More projects on GitHub</a>
                                    <a className="btn btn-info m-t-sm" href="https://bitbucket.org/outofcoffee">Gatehill on Bitbucket</a>
                                </p>
                                <h4>Commercial products</h4>
                                <p>
                                    View our <Link to="/products">products</Link>.
                                </p>
                                <h3>Drop us a line</h3>
                                <p>
                                    If you'd like to get in touch, you can <Link to="/about/contact">contact us</Link>.
                                </p>
                            </div>
                        </div>

                    </div>
                </PageBlock>

                <Footer/>
            </div>
        );
    }
}

export default About;
