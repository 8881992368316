import {Dispatcher} from "flux";

class CoreService {
    static dispatcher = new Dispatcher();

    /**
     * The base path, e.g. https://www.gatehill.io
     *
     * @return {string}
     */
    static getBasePath() {
        return document.location.protocol + '//' + document.location.hostname + (document.location.port ? ':' + document.location.port : '');
    }

    /**
     * Show message in passive error box.
     *
     * @param message
     */
    static showPassiveError(message) {
        CoreService.dispatcher.dispatch({
            type: 'warn',
            payload: {
                visible: true,
                message: message
            }
        });
    }

    /**
     * Show message in passive info box.
     *
     * @param message
     */
    static showPassiveInfo(message) {
        CoreService.dispatcher.dispatch({
            type: 'info',
            payload: {
                visible: true,
                message: message
            }
        });
    }
}

export default CoreService;
