import React, {Component} from "react";
import ShelfNav from "../common/nav/ShelfNav";
import MarketingNav from "../common/nav/MarketingNav";
import Footer from "../common/Footer";
import AnchorLink from "../common/AnchorLink";
import ProductList from "./common/ProductList";

class Home extends Component {
    render() {
        return (
            <div>
                <ShelfNav/>

                <div className="stage" id="stage">

                    <div className="block block-inverse block-fill-height app-header"
                         style={{backgroundImage: 'url(assets/img/splash-background.jpg)'}}>

                        <MarketingNav/>

                        <div className="block-xs-middle p-b-lg">
                            <div className="container">
                                <div className="row">
                                    <div className="col-sm-10 col-md-6">
                                        <h1 className="block-title m-b-sm">Minutes matter</h1>
                                        <p className="lead m-b-md text-muted">Helpful software to get you through your day.</p>
                                        <AnchorLink anchorName="details" className="btn btn-default btn-lg m-t-sm-c-xs m-t-sm-c-sm">Learn more</AnchorLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <section id="details">
                        <ProductList/>
                    </section>

                    <Footer/>
                </div>
            </div>
        );
    }
}

export default Home;
