import React from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga";

/**
 * From https://github.com/react-ga/react-ga/issues/122
 */
export default class GAListener extends React.Component {
    static contextTypes = {
        router: PropTypes.object
    };

    componentDidMount() {
        this.sendPageView(this.context.router.history.location);
        this.context.router.history.listen(this.sendPageView);
    }

    sendPageView(location) {
        ReactGA.set({page: location.pathname});
        ReactGA.pageview(location.pathname);
    }

    render() {
        return this.props.children;
    }
}
