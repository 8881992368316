import React, {Component} from "react";
import TopNav from "../common/nav/TopNav";
import Footer from "../common/Footer";
import PageBlock from "../common/PageBlock";
import ProductList from "./common/ProductList";

class Products extends Component {
    render() {
        return (
            <div>
                <TopNav tab="products"/>

                <PageBlock setMinHeight={false} small={true}>
                    <div className="container text-center">
                        <div className="row">
                            <div className="col-lg-12">
                                <h1 className="page-header">Our Products</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <p>
                                    Helpful software to get you through your day.
                                </p>
                            </div>
                        </div>
                    </div>
                </PageBlock>

                <ProductList/>
                <Footer/>
            </div>
        );
    }
}

export default Products;
