const siteMetadata = {
    id: 'gatehill',
    title: 'Gatehill Software',
    analyticsId: 'UA-22939600-2'
};

class SiteService {
    /**
     * @return {object} site metadata
     */
    getSite = () => siteMetadata;

    /**
     * @return {string}
     */
    getSiteName = () => this.getSite().title;

    /**
     * @return {string} the analytics site ID
     */
    getAnalyticsSiteId = () => this.getSite().analyticsId;
}

const siteService = new SiteService();
const currentSiteName = siteService.getSiteName();

export default siteService;
export {currentSiteName}
