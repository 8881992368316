import React, {Component} from "react";
import CoreService from "../service/CoreService";

class Alert extends Component {
    constructor() {
        super();

        this.state = {
            dispatcherId: undefined,
            info: {
                visible: false,
                message: ''
            },
            warn: {
                visible: false,
                message: ''
            }
        };

        this.scrollIntoView = this.scrollIntoView.bind(this);
    }

    componentDidMount() {
        this.setState({
            dispatcherId: CoreService.dispatcher.register(dispatch => {
                switch (dispatch.type) {
                    case 'info':
                        this.setState({
                            info: dispatch.payload,
                            warn: {
                                visible: false
                            }
                        });
                        this.scrollIntoView();
                        break;

                    case 'warn':
                        this.setState({
                            warn: dispatch.payload,
                            info: {
                                visible: false
                            }
                        });
                        this.scrollIntoView();
                        break;

                    default:
                        break;
                }
            })
        });
    }

    componentWillUnmount() {
        if (this.state.dispatcherId) {
            CoreService.dispatcher.unregister(this.state.dispatcherId);
        }
    }

    scrollIntoView() {
        window.scrollTo(0, 0);
    }

    render() {
        return (<div>
            {this.state.info.visible &&
            <div className="container m-t-lg">
                <div className="row">
                    <div className="alert alert-success" role="alert"
                         id="passive-message"><i className="icon icon-info"/>{' ' + this.state.info.message}
                    </div>
                </div>
            </div>}

            {this.state.warn.visible &&
            <div className="container m-t-lg">
                <div className="row">
                    <div className="alert alert-danger" role="alert"
                         id="passive-message"><i className="icon icon-warning"/>{' ' + this.state.warn.message}
                    </div>
                </div>
            </div>}
        </div>)
    }
}

export default Alert;
