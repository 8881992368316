import React, {Component} from "react";
import "./ProductList.css";
import DarkProductTile from "./DarkProductTile";
import LightProductTile from "./LightProductTile";

class ProductList extends Component {
    render() {
        return (
            <div id="features">
                <DarkProductTile title="Imposter"
                                 superTitle="API Mocks"
                                 logoSrc="/assets/img/imposter_plain_logo15.png"
                                 logoAlt="Imposter logo"
                                 logoStyle={{width: '80%'}}>

                    <p className="lead m-b-sm text-muted">
                        Run standalone mock servers (CLI, Docker, JVM), or embed mocks within your tests (JUnit/JVM and Jest/Node.js).
                    </p>
                    <h4>Features</h4>
                    <p className="lead m-b-sm text-muted">
                        <ul>
                            <li>run standalone mocks in place of real systems</li>
                            <li>turn an OpenAPI/Swagger file into a mock API for dev or QA</li>
                            <li>validate your API requests against an OpenAPI schema</li>
                            <li>capture data and use response templates</li>
                            <li>serve conditional and dynamic responses</li>
                        </ul>
                    </p>
                    {/*<a href="https://imposter.sh"
                       className="btn btn-primary btn-lg btn-borderless m-t-sm m-r-sm">
                        Website
                    </a>*/}
                    <a href="https://github.com/gatehill/imposter-cli"
                       className="btn btn-primary btn-lg btn-borderless m-t-sm">
                        View on GitHub
                    </a>
                </DarkProductTile>

                <div className="m-b-lg">
                    <LightProductTile title="Beanlist"
                                      superTitle="To do list"
                                      logoSrc="/assets/img/beanlist_512.png"
                                      logoAlt="Beanlist logo"
                                      logoStyle={{width: '80%'}}>

                        <p className="lead m-b-sm">A popular To Do and task list application for <em>Google
                            Assistant</em> and on the
                            web. Add and remove items from your shopping list with your voice. View and edit on
                            the web.</p>
                        <a href="https://beanlist.io"
                           className="btn btn-primary btn-lg btn-borderless m-t-sm m-r-sm">
                            Website
                        </a>
                        <a href="https://assistant.google.com/services/a/uid/000000128b377c14"
                           className="btn btn-primary btn-lg btn-borderless m-t-sm">
                            Google Assistant
                        </a>
                    </LightProductTile>
                </div>

                <DarkProductTile title="TextBusy"
                                 superTitle="Forward SMS messages"
                                 logoSrc="/assets/img/textbusy_512.png"
                                 logoAlt="TextBusy logo"
                                 logoStyle={{width: '80%'}}>

                    <p className="lead m-b-md text-muted">An inexpensive solution to reading your text (SMS) messages
                        when away from your
                        phone. You can start using TextBusy right now for less than &pound;1!</p>
                    <a href="https://market.android.com/details?id=org.smsforward"
                       className="btn btn-primary btn-lg btn-borderless">
                        Download <span className="hidden-xs">for Android</span>
                    </a>
                </DarkProductTile>

                <div className="m-b-lg">
                    <LightProductTile title="Open Source"
                                      superTitle="On GitHub"
                                      logoSrc="/assets/img/GitHub-Mark-120px-plus.png"
                                      logoAlt="GitHub logo"
                                      logoStyle={{width: '60%'}}>

                        <p className="lead m-b-sm">We maintain and contribute to a number of open
                            source projects.</p>

                        <a className="btn btn-primary btn-lg btn-borderless m-t-sm m-r-sm"
                           href="https://github.com/gatehill">Gatehill on GitHub</a>
                        <a className="btn btn-primary btn-lg btn-borderless m-t-sm"
                           href="https://github.com/outofcoffee?tab=repositories">More projects <span className="hidden-xs">on GitHub</span></a>
                    </LightProductTile>
                </div>
            </div>
        );
    }
}

export default ProductList;
