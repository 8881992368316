import React, {Component} from "react";
import {Link} from "react-router-dom";

class ShelfNav extends Component {
    dismissShelf() {
        document.querySelector('.navbar-toggle').click();
    }

    render() {
        return (
            <div className="stage-shelf stage-shelf-right hidden" id="sidebar">
                <ul className="nav nav-bordered nav-stacked">
                    <li className="nav-header">About</li>
                    <li>
                        <Link onClick={this.dismissShelf} to="/products">Products</Link>
                    </li>
                    <li>
                        <Link onClick={this.dismissShelf} to="/about">About us</Link>
                    </li>
                    <li>
                        <Link onClick={this.dismissShelf} to="/about/contact">Contact</Link>
                    </li>
                    <li className="nav-divider" />
                    <li className="nav-header">Legal</li>
                    <li>
                        <Link onClick={this.dismissShelf} to="/legal/terms">Terms</Link>
                        <Link onClick={this.dismissShelf} to="/legal/privacy">Privacy</Link>
                        <Link onClick={this.dismissShelf} to="/legal/license">License</Link>
                    </li>
                </ul>
            </div>
        )
    }
}

export default ShelfNav;
