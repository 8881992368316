import React, {Component} from "react";
import {Link} from "react-router-dom";
import "./MarketingNav.css"

class MarketingNav extends Component {
    render() {
        return (
            <nav className="navbar navbar-transparent navbar-fixed-top navbar-padded app-navbar p-t-md">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed p-x-0"
                                data-target="#stage" data-toggle="stage" data-distance="-250">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                        </button>
                        <Link className="navbar-brand" to="/">
                            <img
                                alt="Gatehill Software logo"
                                className="brand-icon-splash"
                                src="/assets/img/gatehillsoftware_logo01_128_white.png"/>
                        </Link>
                    </div>
                    <div className="navbar-collapse collapse text-uppercase">
                        <ul className="nav navbar-nav navbar-right">
                            <li>
                                <Link to="/products">Products</Link>
                            </li>
                            <li>
                                <Link to="/about">About</Link>
                            </li>
                            <li>
                                <Link to="/about/contact">Contact</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        );
    }
}

export default MarketingNav;
