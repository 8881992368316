import React, { Component } from 'react';
import {Link} from "react-router-dom";

class Footer extends Component {
  render() {
    return (
        <div className="block block-inverse app-footer">
            <div className="container">
                <div className="row">
                    <div className="col-sm-5 m-b-md">
                        <ul className="list-unstyled list-spaced">
                            <li className="m-b"><h6 className="text-uppercase">About Gatehill Software</h6></li>
                            <li className="text-muted">
                                We believe your minutes matter. We've been building software
                                for over two decades to help people make the most
                                of their time. <Link to="/about">Learn more</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="col-sm-offset-1 col-sm-2 m-b-md">
                        <ul className="list-unstyled list-spaced">
                            <li className="m-b"><h6 className="text-uppercase">Products</h6></li>
                            <li className="text-muted"><a href="https://beanlist.io">Beanlist on the web</a></li>
                            <li className="text-muted"><a href="https://assistant.google.com/services/a/uid/000000128b377c14">Beanlist on Google Assistant</a></li>
                        </ul>
                    </div>
                    <div className="col-sm-2 m-b-md">
                        <ul className="list-unstyled list-spaced">
                            <li className="m-b"><h6 className="text-uppercase">Legal</h6></li>
                            <li className="text-muted"><Link to="/legal/terms">Terms and Conditions</Link></li>
                            <li className="text-muted"><Link to="/legal/privacy">Privacy</Link></li>
                            <li className="text-muted"><Link to="/legal/license">License</Link></li>
                        </ul>
                    </div>
                    <div className="col-sm-2 m-b-md">
                        <ul className="list-unstyled list-spaced">
                            <li className="m-b"><h6 className="text-uppercase">About us</h6></li>
                            <li className="text-muted"><Link to="/about">About</Link></li>
                            <li className="text-muted"><Link to="/about/contact">Contact</Link></li>
                            <li className="text-muted"><a href="https://twitter.com/gatehill_io">Twitter</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default Footer;
