import React, {Component} from "react";
import ReactGA from "react-ga";
import SiteService from "./modules/service/SiteService";
import {BrowserRouter, Route, Switch} from 'react-router-dom'
import Home from "./modules/views/Home";
import About from "./modules/views/about/About";
import Terms from "./modules/views/legal/Terms";
import EULA from "./modules/views/legal/EULA";
import Privacy from "./modules/views/legal/Privacy";
import ScrollToTop from "./modules/common/ScrollToTop";
import Contact from "./modules/views/about/Contact";
import Products from "./modules/views/Products";
import GAListener from "./modules/common/GAListener";

// track page views
ReactGA.initialize(SiteService.getAnalyticsSiteId(), {
    testMode: /localhost/.test(document.location.href)
});

class App extends Component {
    render() {
        return (
            <BrowserRouter>
                <GAListener>
                    <ScrollToTop>
                        <div>
                            {/* Marketing */}
                            <Route exact path="/" component={Home}/>
                            <Route exact path="/products" component={Products}/>

                            {/* Legal */}
                            <Route exact path="/legal/terms" component={Terms}/>
                            <Route exact path="/legal/license" component={EULA}/>
                            <Route exact path="/legal/privacy" component={Privacy}/>

                            {/* About */}
                            <Switch>
                                <Route exact path="/about" component={About}/>
                                <Route exact path="/about/contact" component={Contact}/>
                            </Switch>
                        </div>
                    </ScrollToTop>
                </GAListener>
            </BrowserRouter>
        );
    }
}

export default App;
