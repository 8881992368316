import React, {Component} from "react";
import Alert from "./Alert";
import "./PageBlock.css"
import PropTypes from "prop-types";

class PageBlock extends Component {
    render() {
        const minHeightClass = (this.props.setMinHeight ? "page-block-min-height" : "");
        if (this.props.small) {
            return (
                <div className={`page-block-sm ${minHeightClass}`}><Alert/>{this.props.children}</div>
            )
        } else {
            return (
                <div className={`page-block ${minHeightClass}`}><Alert/>{this.props.children}</div>
            )
        }
    }
}

PageBlock.propTypes = {
    small: PropTypes.bool,
    setMinHeight: PropTypes.bool
};

PageBlock.defaultProps = {
    small: false,
    setMinHeight: true
};

export default PageBlock;
