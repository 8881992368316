import React, {Component} from "react";
import "./ProductList.css";
import PropTypes from "prop-types";

class LightProductTile extends Component {
    render() {
        return (
            <div className="block block-secondary app-iphone-block">
                <div className="container">
                    <div className="row app-align-center m-b-lg">
                        <div className="col-sm-4 hidden-xs">
                            <img alt={this.props.logoAlt} src={this.props.logoSrc} style={this.props.logoStyle}/>
                        </div>
                        <div className="col-sm-8">
                            <h6 className="text-muted text-uppercase">{this.props.superTitle}</h6>
                            <h3 className="m-t-0"><strong>{this.props.title}</strong></h3>
                            <div>{this.props.children}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

LightProductTile.propTypes = {
    superTitle: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    logoAlt: PropTypes.string.isRequired,
    logoSrc: PropTypes.string.isRequired,
    logoStyle: PropTypes.string,
};

export default LightProductTile;
