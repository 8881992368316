/*
 * Work-around for: https://github.com/ReactTraining/react-router/issues/394
 */

import React, {Component} from "react";
import PropTypes from "prop-types";

class AnchorLink extends Component {
    constructor() {
        super();

        this.onAnchorLinkClick = this.onAnchorLinkClick.bind(this);
    }

    render() {
        return (<a className={this.props.className} onClick={this.onAnchorLinkClick} href={`#${this.props.anchorName}`}>{this.props.children}</a>);
    }

    onAnchorLinkClick(e) {
        e.preventDefault();

        let anchorElement = document.getElementById(this.props.anchorName);
        if (anchorElement) {
            anchorElement.scrollIntoView();
        }
    }
}

AnchorLink.propTypes = {
    anchorName: PropTypes.string.isRequired,
    className: PropTypes.string,
};

export default AnchorLink;
